<template>
  <div class="container">
    <div class="card border-0 shadow-sm">
      <div class="card-body">
        <h3 class="fw-bold text-success">Talks</h3>

        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Created</th>
            </tr>
          </thead>
          <tbody>
            <tr class="cursor-pointer" v-for="t in talks" :key="t.id">
              <th @click="$router.push('/talks/' + t.id)" scope="row">
                {{ t.id }}
              </th>
              <td @click="$router.push('/talks/' + t.id)">{{ t.name }}</td>
              <td @click="$router.push('/talks/' + t.id)">{{ t.type }}</td>
              <td @click="$router.push('/talks/' + t.id)">
                {{ t.created_at | formatDate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      talks: [],
    };
  },
  methods: {
    fetchTalks() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/talks")
        .then(({ data }) => {
          this.talks = data;
        });
    },
  },
  created() {
    this.fetchTalks();
  },
  mounted() {
    this.$store.sidebarOpen = false;
  },
};
</script>

<style>
</style>